import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { UniversalStorage } from '../storage/universal.storage';
import { PATH_URLS } from '../const';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  error = new Error();

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(UniversalStorage) private appStorage: Storage,
    private router: Router
  ) {}

  public get(path: string, params?: any) {
    const token = this.appStorage.getItem('tinode_token');
    const headers = new HttpHeaders()
      .set('localization', this.locale ? this.locale : 'en')
      .set('authorization', token ? 'Bearer ' + token : '');
    return this.http
      .get<any>(path, {
        params,
        headers,
      })
      .pipe(catchError(this.handleError()));
  }

  public post(path: string, body: any | null, params?: any) {
    const token = this.appStorage.getItem('tinode_token');
    const headers = new HttpHeaders()
      .set('localization', this.locale ? this.locale : 'en')
      .set('authorization', token ? 'Bearer ' + token : '');
    return this.http
      .post<any>(path, body, {
        params,
        headers,
      })
      .pipe(catchError(this.handleError()));
  }

  public put(path: string, body: any | null, params?: any) {
    const token = this.appStorage.getItem('tinode_token');
    const headers = new HttpHeaders()
      .set('localization', this.locale ? this.locale : 'en')
      .set('authorization', token ? 'Bearer ' + token : '');
    return this.http
      .put<any>(path, body, {
        params,
        headers,
      })
      .pipe(catchError(this.handleError()));
  }

  public delete(path: string, params?: any) {
    const token = this.appStorage.getItem('tinode_token');
    const headers = new HttpHeaders()
      .set('localization', this.locale ? this.locale : 'en')
      .set('authorization', token ? 'Bearer ' + token : '');
    return this.http
      .delete<any>(path, {
        params,
        headers,
      })
      .pipe(catchError(this.handleError()));
  }

  public uploader(path: string, body: FormData) {
    const token = this.appStorage.getItem('tinode_token');
    const headers = new HttpHeaders()
      .set('localization', this.locale ? this.locale : 'en')
      .set('authorization', token ? 'Bearer ' + token : '');
    const req: HttpRequest<FormData> = new HttpRequest('POST', path, body, {
      reportProgress: false,
      headers,
    });
    return this.http.request(req).pipe(catchError(this.handleError()));
  }

  private handleError<T>() {
    return (error: any): Observable<T> => {
      if (error.status === 302 || error.status === 301) {
        this.handleRedirect(error);
      } else {
        if (error.status === 403 || error.status === 401) {
          this.appStorage.removeItem('tinode_token');
          this.router.navigate([PATH_URLS.login]).then();
        } else {
          if (error.error?.messages) {
            console.log(error.error.messages);
          } else if (error.error?.error) {
            if (error.error.error.message.startsWith('Token')) {
              this.router.navigate([PATH_URLS.login]).then();
            }
          }
        }
      }

      return throwError(() => error);
    };
  }

  handleRedirect(error: { error: { redirect: any } }) {
    let url = error.error.redirect;
    if (url.indexOf('http') !== -1) {
      window.location.href = url;
    } else {
      const protocol = window.location.protocol;
      url = `${protocol}//${url}`;
      window.location.href = url;
    }
  }
}

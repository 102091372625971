import { InjectionToken } from '@angular/core';

const REQUEST = new InjectionToken('REQUEST');
const RESPONSE = new InjectionToken('RESPONSE');

/**
 * Generated bundle index. Do not edit.
 */

export { REQUEST, RESPONSE };


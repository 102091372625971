export class Drafty {
    static parse(a: any) { }
    static isPlainText(a: any): boolean {
        return false;
    }
    static getContentType() {
      return 'text/x-drafty';
    }
    static hasAttachments(a?: any) {
        return false;
    }
    static attachments(a: any, b: any) { }
}

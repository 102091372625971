import { Inject, Injectable } from '@angular/core';

import { ApiService } from './api.service';

import { API_URLS, PATH_URLS } from '../const';
import { BehaviorSubject } from 'rxjs';
import { UniversalStorage } from '../storage/universal.storage';
import { Utilities } from '../model/tinode';
import { UserInterface } from '../model/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user$: BehaviorSubject<UserInterface | null> =
    new BehaviorSubject<UserInterface | null>(null);

  constructor(
    @Inject(UniversalStorage) private appStorage: Storage,
    private api: ApiService
  ) {}

  loadUser() {
    return this.api.get(API_URLS.get_user);
  }

  getToken() {
    return this.appStorage.getItem('tinode_token');
  }

  setToken(token: string) {
    this.appStorage.setItem('tinode_token', token);
  }

  logout() {
    this.appStorage.removeItem('tinode_token');
    Utilities.removeItem('auth-token');
  }
}
